<template>
    <div class="test p_20   ">
        <div class="con">
            <van-cell-group>
                <van-field v-model="value" label-width="50" label="url：" placeholder="请输入url" />
            </van-cell-group>
            <br>
            <van-button type="primary" @click="init">点击</van-button>
            <br>
            <div id="msg" class="pd_20">
                <div v-for="(val,key,index) in json" :key="index">
                    <h1>{{key}}</h1>
                    <div v-if="key == '例题'">
                        <div v-for="(item,n) in val" :key="n">
                            <div v-if="item[0]=='str'">{{item[1]}}</div>
                            <div v-else-if="item[0]=='tex'" :id="'tex-'+index+'-'+n"></div>
                        </div>
                    </div>
                    <div v-else-if="key == '答题方式'">
                        {{val}}
                    </div>
                    <div v-else-if="key == '选项'">
                        <span v-for="(item,i) in val" :key="i">{{item}}</span>
                    </div>
                    <div v-else-if="key == '分步'">
                        <div v-for="(list,num) in val" :key="num">
                            <h2>分步{{num}}</h2>
                            <div v-for="(v,k,i) in list" :key="i">
                                <div v-if="k=='步骤'">{{k}}:{{v}}</div>
                                <div v-else-if="k=='解析'">
                                    <h3>{{k}}:</h3>
                                    <div v-for="(list,nn) in v[0]" :key="nn">
                                        <span v-if="list[0]=='tex'" :id="'fb-'+ index + '-' + num + '-'+nn"></span>
                                        <span v-else-if="list[0]=='str'">{{list[1]}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="msg1"></div>
        </div>
    </div>
</template>

<script>
    var $ = require( "jquery" );
    export default {
        data() {
            return {
                value:'',
                json:{},
            }
        },

        methods: {
            init() {
                if(this.value===''){
                    this.$toast('请输入url')
                    return false;
                }
                $.ajax({
                    url:this.value,
                    success:(json=>{
                        console.log('json', json);
                        this.json = json;
                        this.$nextTick().then(()=>{
                            for(let key in json){
                                let keyList = Object.keys(json);
                                let index = keyList.indexOf(key);
                                let arr = json[key];
                                if(key == '例题'){
                                    arr.map((e,n)=>{
                                        if(e[0]=='tex'){
                                            this.$kx.render(e[1], document.getElementById('tex-'+index + '-' + n), {
                                                displayMode: true,
                                            });
                                        }
                                    })
                                }else if(key == '分步'){
                                    arr.map((e,num)=>{
                                        for(let k in e){
                                            if(k=='解析'){
                                                let list = e[k][0];
                                                list.map((el,n)=>{
                                                    if(el[0]=='tex'){
                                                        let id = 'fb-' + index + '-' + num + '-' + n;
                                                        // console.log('id:'+id, el[1]);
                                                        this.$kx.render(el[1], document.getElementById(id), {
                                                            displayMode: true,
                                                        });
                                                    }
                                                })
                                            }
                                        }
                                    })
                                }
                            }
                        })
                    })
                })
                
            }
        },
        created () {
            // this.$store.commit('setHashName','Home');
        }, 
        mounted () {
            let dom = document.getElementById('msg1');
            //               x=\cos x \text 在 (-\infty,+\infty) \text 
            this.$kx.render('x=\\cos x在 (-\\infty,+\\infty)', dom, {
                displayMode: true,
            });
        },
    }
</script>

<style lang="less" scoped>
    .header{
        display: none;
    }
    .con{
        // text-align: left;
    }
    .item{
        display: inline-block;
    }
    h,h1,h2,h3,h4{
        text-align: left;
    }
</style>